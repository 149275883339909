import MathNodeDetailsType from "./math/index";
import InputDetailsComp from "./input/index";
import OutputDetailsComp from "./output/index";
import TextDetailsComp from "./text/index";
import CondDetailsComp from "./cond/index";
import JsonDetailsComp from "./json/index";

export const MathDetailsComponent = MathNodeDetailsType;
export const InputDetailsComponent = InputDetailsComp;
export const OutputDetailsComponent = OutputDetailsComp;
export const TextDetailsComponent = TextDetailsComp;
export const CondDetailsComponent = CondDetailsComp;
export const JsonDetailsComponent = JsonDetailsComp;