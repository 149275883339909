export default Object.freeze({
  NUMBER: {
    code:"num",
    name:"Numérica"
  },
  TEXT: {
    code:"text",
    name:"Caracteres"
  },
  JSON:{
    code: "json",
    name: "JSON"
  },
  NONE:{
    code: "none",
    name: "Tipo de Variável"
  }
});