export const fontFamily = "Poppins"

export const white = "#ffffff";
export const whiteLight = "#f8f8f8";
export const whiteLightHover = "#dcdcdc";
export const whiteHover = "#efefef";
export const creamWhite = "#a6ffc9";

export const smokeWhiteLight = "#c5c5c5"
export const smokeWhiteLightHover = "#dfdfdf"
export const smokeWhite = "#c5b6b4";
export const smokeWhiteHover = "#C2C2C2";
export const smoke = "#97A2A8";
export const smokeHover = "#696969";
export const denseSmoke = "#4d4d4d";

export const denseSmokeBlack = "#1d2d3d";
export const smokeBlack = "#1d1d1d";

export const easyNavy = "#305da7";
export const denseLightBlue = "#0074eb";
export const blueBayoux = "#475F76";

export const redSalmon = "#Ff7d77";
export const vividRed = "#dc3c31";
export const vividRedHover = "#c22219";
export const vividRedHoverActive = "#cb5650";

export const vividGreen = "#5cb85c";
export const lightVividGreen = "#7be27b";
export const softGreen = "#7cd38d";

export const easyOrange = "#cc8400"

export const headerHeight = '8vh';
