import styled from "styled-components";

export default styled.div`

  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  
  .dropdown-math-operation{
    height: 45px;
  }
`;