import styled from "styled-components";

export default styled.div`

    display:flex;
    align-items: center;
    justify-content: center;

    height: 1px;
    /* height: 10px;
    background-color: black; */
    
    width: 200px;

    .worker{
        width: 0%;
        height: 0%;
        display: none;
    }

    .handle_bottom{
        margin-bottom: 9px;
    }
`